import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Radio } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "radio"
    }}>{`Radio`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Radio from '@mfrm/mfcl/Radio'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Wrapper for input type radio.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Radio} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Radio label=\"King\" />\n<Radio label=\"Queen\" />\n<Radio label=\"Twin\" />\n<Radio label=\"Full\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Radio,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Radio label="King" mdxType="Radio" />
  <Radio label="Queen" mdxType="Radio" />
  <Radio label="Twin" mdxType="Radio" />
  <Radio label="Full" mdxType="Radio" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      